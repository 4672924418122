import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, Outlet, useLocation, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  Card,
  Tooltip
} from '@material-ui/core';
import { departmentApi } from '../api/department';
import { DepartmentSummeryTable } from '../components/department/department-summery-table';
import { DepartmentSummeryEvaluation } from '../components/department/department-summery-evaluation';
import { ConfirmationDialog } from '../components/confirmation-dialog';
import { useDialog } from '../hooks/use-dialog';
import { useMounted } from '../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { Calendar as CalendarIcon } from '../icons/calendar';
import { Cash as CashIcon } from '../icons/cash';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../icons/exclamation-outlined';
import { ShoppingCart as ShoppingCartIcon } from '../icons/shopping-cart';

export const Department = () => {
  const mounted = useMounted();
  // const location = useLocation();
  const [banDialogOpen, handleOpenBanDialog, handleCloseBanDialog] = useDialog();
  const [departmentState, setDepartmentState] = useState({ isLoading: true });

  const [clickedRow, setClickedRow] = useState();

  const params = useParams();
  // console.log(`params: ${JSON.stringify(params)}`);

  const m = moment();
  const temp = m.date() < 11 ? m.subtract(2, 'month') : m.subtract(1, 'month');
  m.subtract(11, 'month');
  const strStartYm = m.format('YYYYMM');
  m.add(12, 'month');
  const tempYear = m.year();
  const tempMonth = m.month();
  const paramEndDate = moment(`${tempYear}-${(tempMonth + 1)}-01`).subtract(1, 'day');
  const strEndYm = paramEndDate.format('YYYYMM');

  const getDepartment = async () => {
    setDepartmentState(() => ({ isLoading: true }));

    try {
      const result = await departmentApi.getDepartmentDetail({
        deptCode: params.deptCode,
        start_ym: strStartYm,
        end_ym: strEndYm,
      });

      if (mounted.current) {
        setDepartmentState(() => ({
          isLoading: false,
          data: result.data
        }));
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setDepartmentState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  };

  useEffect(() => {
    getDepartment().catch(console.error);
  }, []);

  const handleBanAccount = () => {
    handleCloseBanDialog();
    toast.error('This action is not available on demo');
  };

  const getRow = (row) => {
    if (row) return row;
    console.log(`departmentState.data: ${JSON.stringify(departmentState.data)}`);
    return departmentState.data?.scores[0];
  };

  const renderContent = () => {
    if (departmentState.isLoading) {
      return (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      );
    }

    if (departmentState.error) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <ExclamationOutlinedIcon />
            <Typography
              color="textSecondary"
              sx={{ mt: 2 }}
              variant="body2"
            >
              {departmentState.error}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ py: 1 }}>
          <Box>
            <Button
              color="primary"
              component={RouterLink}
              startIcon={<ArrowLeftIcon />}
              to="/dashboard/reports"
              variant="text"
            >
              部署一覧
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              mb: 2
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {departmentState.data.deptName}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ mt: 4, ml: 2 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                container
                item
                lg={5}
                spacing={3}
                sx={{ height: 'fit-content' }}
                xs={12}
              >
                <Card
                  // variant="outlined"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    mr: 1
                  }}
                >
                  <DepartmentSummeryTable
                    scores={departmentState.data?.scores}
                    scoresCount={departmentState.data?.scoreCount}
                    error={departmentState.error}
                    isLoading={departmentState.isLoading}
                    setClickedRow={setClickedRow}
                  />
                </Card>
              </Grid>
              <Grid
                container
                item
                lg={7}
                spacing={3}
                sx={{ height: 'fit-content' }}
                xs={12}
              >
                <Card
                  // variant="outlined"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1
                  }}
                >
                  <DepartmentSummeryEvaluation
                    row={getRow(clickedRow)}
                  />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Outlet />
        <ConfirmationDialog
          message="Are you sure you want to ban this account? This can't be undone."
          onCancel={handleCloseBanDialog}
          onConfirm={handleBanAccount}
          open={banDialogOpen}
          title="Ban Department"
          variant="error"
        />
      </>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {renderContent()}
      </Container>
    </Box>
  );
};
