import { throttle } from '../config';
import { applyFilters } from '../utils/apply-filters';
import { applyPagination } from '../utils/apply-pagination';
import { applySort } from '../utils/apply-sort';
import { wait } from '../utils/wait';
import http from '../utils/http';

class DepartmentApi {
  async getDepartments(options) {
    try {
      const result = await http.get('/api/departments_recent',
        {
          params: {
            ym: options.ym
          }
        });
      return result;
    } catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`);
      return error.response;
    }
  }

  async getDepartmentsSimple(options) {
    try {
      const result = await http.get('/api/departments_simple',
        {
          params: {
            ym: options.ym
          }
        });
      return result;
    } catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`);
      return error.response;
    }
  }

  async getDepartmentDetail(options) {
    try {
      const result = await http.get('/api/department_detail',
        {
          params: {
            deptCode: options.deptCode,
            start_ym: options.start_ym,
            end_ym: options.end_ym
          }
        });
      return result;
    } catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`);
      return error.response;
    }
  }
}

export const departmentApi = new DepartmentApi();
