import { throttle } from '../config';
import { applyFilters } from '../utils/apply-filters';
import { applyPagination } from '../utils/apply-pagination';
import { applySort } from '../utils/apply-sort';
import { wait } from '../utils/wait';
import http from '../utils/http';

class OverviewApi {
  async getOverview(strStartDate, strEndDate) {
    try {
      const result = await http.get('/api/overview',
        {
          params: {
            start: strStartDate,
            end: strEndDate
          }
        });
      return result;
    } catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`);
      return error.response;
    }
  //   await http.get('/api/overview',
  //     {
  //       params: {
  //         start: strStartDate,
  //         end: strEndDate
  //       }
  //     }).then((response) => {
  //     console.log(`length: ${response.data.chart0.datasets.length}`);
  //     if (response.data.chart0.datasets.length === 0) {
  //       return '対象のデータが存在しません。対象期間をご確認ください。';
  //     }
  //     console.log(response);
  //     return response.data.chart0;
  //   }).catch((error) => error);
  }
}

export const overviewApi = new OverviewApi();
