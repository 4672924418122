import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  Card,
  Tooltip
} from '@material-ui/core';
import { RecommendedComponents } from '../components/recommended-components';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';

// const data = [
//   {
//     key: '1',
//     title: 'できていますか？',
//     desc: 'ホリプロスカウトキャラバンには友達に誘われて参加した。その気になった理由は「部活を休むための口実」だった[21]。特技ではやることがなかったので学校で流行っていたウサギのまねをした[22][23]。バラエティ番組に出演する際に、特技として披露することもある。芸名はインターネット上で公募し、応募された16,000件の中から本人とスタッフで選び、2001年2月28日に「綾瀬はるか」に決定した[24]。非常に真面目な性格で演技に対する真摯な態度は共演者、スタッフからも評価が高い。演技に関して納得が行くまで監督と話し合うことも多い[25]',
//     img: '/static/product-01.png',
//     linkName: '詳細はこちら',
//     linkUrl: 'http://google.com'
//   },
//   {
//     key: '2',
//     title: 'どうですか？',
//     desc: 'ホリプロスカウトキャラバンには友達に誘われて参加した。その気になった理由は「部活を休むための口実」だった[21]。特技ではやることがなかったので学校で流行っていたウサギのまねをした[22][23]。バラエティ番組に出演する際に、特技として披露することもある。芸名はインターネット上で公募し、応募された16,000件の中から本人とスタッフで選び、2001年2月28日に「綾瀬はるか」に決定した[24]。非常に真面目な性格で演技に対する真摯な態度は共演者、スタッフからも評価が高い。演技に関して納得が行くまで監督と話し合うことも多い[25]',
//     img: '/static/product-02.png',
//     linkName: '詳細はこちら',
//     linkUrl: 'http://google.com'
//   },
//   {
//     key: '3',
//     title: 'いけそうですか？',
//     desc: 'ホリプロスカウトキャラバンには友達に誘われて参加した。その気になった理由は「部活を休むための口実」だった[21]。特技ではやることがなかったので学校で流行っていたウサギのまねをした[22][23]。バラエティ番組に出演する際に、特技として披露することもある。芸名はインターネット上で公募し、応募された16,000件の中から本人とスタッフで選び、2001年2月28日に「綾瀬はるか」に決定した[24]。非常に真面目な性格で演技に対する真摯な態度は共演者、スタッフからも評価が高い。演技に関して納得が行くまで監督と話し合うことも多い[25]',
//     img: '/static/product-03.png',
//     linkName: '詳細はこちら',
//     linkUrl: 'http://google.com'
//   }
// ];

export const DepartmentDetail = () => {
  const location = useLocation();
  console.log(`sate-child: ${JSON.stringify(location.state)}`);
  const { data } = location.state;
  return (
    <>
      <Box sx={{ py: 1 }}>
        <Box
          sx={{ ml: 2 }}
        >
          <Button
            color="primary"
            component={RouterLink}
            startIcon={<ArrowLeftIcon />}
            to={`/dashboard/reports/${data?.deptCode}`}
            variant="text"
          >
            {data?.deptName}
          </Button>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            mb: 2,
            ml: 2
          }}
        >
          <Typography
            color="textPrimary"
            variant="h4"
          >
            {/* {departmentState.data.deptName} */}
            評価と推奨アクション
          </Typography>
          {/* <Box sx={{ flexGrow: 1 }} />
          <ActionsMenu actions={actions} /> */}
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'left',
            flexDirection: 'column',
            display: 'flex',
            m: 2,
            p: 1,
            border: 2,
            borderColor: '#cccccc',
            borderRadius: 1
          }}
        >
          <Typography variant="h5">
            評価のコメント
          </Typography>
          <Typography
            variant="body1"
            sx={{
              ml: 2,
              mt: 2
            }}
          >
            {data?.comment}
          </Typography>
        </Box>
        <Box
          sx={{
            alignItems: 'left',
            flexDirection: 'column',
            display: 'flex',
            m: 2,
            p: 1,
            border: 2,
            borderColor: '#cccccc',
            borderRadius: 1
          }}
          maxHeight="460px"
          overflow="auto"
          flex={1}
          flexDirection="column"
          display="flex"
          // p={2}
        >
          <Tooltip
            arrow
            title="ご参考までに、Google Workspace 利用の価値を、獲得していただくための施策案をご提示しています。"
          >
            <Typography variant="h5">
              推奨アクション
            </Typography>
          </Tooltip>
          <Box>
            <RecommendedComponents
              data={data?.recommends}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
