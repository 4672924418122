import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Box, Typography } from '@material-ui/core';
import toast from 'react-hot-toast';
import { clientEenv } from '../../config';

export const LoginJwt = () => {
  const responseGoogle = (credentialResponse) => {
    console.log(JSON.stringify(credentialResponse));
    const { credential, clientId, selectBy } = credentialResponse;
    console.log(`credential: ${credential}, clientId: ${clientId}, selectBy: ${selectBy}`);
    console.log(`clientEnv: ${clientEenv}`);
    toast('確認しています。少しお待ちください。', {
      icon: '⏳',
    });
    const url = clientEenv === 'development' ? 'http://localhost:8000' : 'https://mr2ent-server.an.r.appspot.com/';
    axios.create({
      baseURL: url,
    }).post('/swap_token',
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest',
          'X-Google-OAuth2-Type': 'client'
        },
        credential
      }).then(
      (res) => {
        console.log(`res: ${JSON.stringify(res)}`);
        const user = {};
        user.accessToken = res.data.access_token;
        user.userInfo = res.data.userInfo;
        sessionStorage.setItem('user', JSON.stringify(user));
        console.log(`29-token: ${user.accessToken}`);
        const url = document.URL;
        if (url.match(/login/)) {
          window.location.href = (user.userInfo.has_dict) ? `${window.location.protocol}//${window.location.host}/dashboard/reports` : `${window.location.protocol}//${window.location.host}/dashboard/simplified`;
          if (user.userInfo.initial_data_uu === 9) {
            window.location.href = `${window.location.protocol}//${window.location.host}/dashboard/reports`;
          } else if (user.userInfo.initial_data_cu === 9) {
            window.location.href = `${window.location.protocol}//${window.location.host}/dashboard/simplified`;
          } else {
            window.location.href = `${window.location.protocol}//${window.location.host}/dashboard/config/notifications`;
          }
        } else {
          window.location.reload();
        }
      }
    ).catch((err) => {
      console.log(`err: ${JSON.stringify(err.response)}`);
      if (!err.response) {
        console.log(`err2: ${JSON.stringify(err.response)}`);
        toast.error('サーバーが起動していない可能性があります。');
      } else if (err.response.status === 401) {
        toast.error(`利用が制限されています。
        詳細メッセージ: ${err.response.data.detail}`);
      } else if (err.response.status === 403) {
        toast.error(`Master Program レポート機能 にユーザー登録されていないようです。
        詳細メッセージ: ${err.response.data.detail}`);
      } else {
        toast.error(`Master Program レポート機能 側の認証においてエラーが発生しました。
        詳細メッセージ: ${err.response.data.detail}`);
      }
    });
  };

  const errorGoogle = (response) => {
    console.log(JSON.stringify(response));
    toast.error(`Google Workspace アカウントが有効でないようです。 ${JSON.stringify(response)}`);
  };

  return (
    <div>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          my: 1
        }}
      >
        <Typography
          color="textPrimary"
          variant="h5"
        >
          ログイン
        </Typography>
      </Box>
      <GoogleOAuthProvider clientId="66010361659-4c72p1cfrrs43e2drg2v23u55ovk0ch8.apps.googleusercontent.com">
        <GoogleLogin
          onSuccess={responseGoogle}
          onError={errorGoogle}
        />
      </GoogleOAuthProvider>
    </div>
  );
};
