import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, Outlet, useLocation, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  Card
} from '@material-ui/core';
import { overviewApi } from '../api/overview';
import { DepartmentSummeryTable } from '../components/department/department-summery-table';
import { DepartmentSummeryEvaluation } from '../components/department/department-summery-evaluation';
import { ConfirmationDialog } from '../components/confirmation-dialog';
import { useDialog } from '../hooks/use-dialog';
import { useMounted } from '../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { Calendar as CalendarIcon } from '../icons/calendar';
import { Cash as CashIcon } from '../icons/cash';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../icons/exclamation-outlined';
import { ShoppingCart as ShoppingCartIcon } from '../icons/shopping-cart';

export const Overview = () => {
  const mounted = useMounted();
  // const location = useLocation();
  const [banDialogOpen, handleOpenBanDialog, handleCloseBanDialog] = useDialog();
  const [overviewState, setOverviewState] = useState({ isLoading: true });

  const [clickedRow, setClickedRow] = useState();

  const params = useParams();
  console.log(`params: ${JSON.stringify(params)}`);

  const getOverview = useCallback(async () => {
    setOverviewState(() => ({ isLoading: true }));

    try {
      const result = await overviewApi.getOverview('2021-08-01', '2021-08-31');
      console.log(`result: ${result}`);

      if (mounted.current) {
        setOverviewState(() => ({
          isLoading: false,
          data: result
        }));
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setOverviewState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);

  useEffect(() => {
    getOverview().catch(console.error);
  }, []);

  const handleSendVerification = () => {
    toast.error('This action is not available on demo');
  };

  const handleSendPasswordReset = () => {
    toast.error('This action is not available on demo');
  };

  const handleBanAccount = () => {
    handleCloseBanDialog();
    toast.error('This action is not available on demo');
  };

  const getRow = (row) => {
    if (row) return row;
    return overviewState.data?.scores[0];
  };

  const renderContent = () => {
    if (overviewState.isLoading) {
      return (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      );
    }

    if (overviewState.error) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <ExclamationOutlinedIcon />
            <Typography
              color="textSecondary"
              sx={{ mt: 2 }}
              variant="body2"
            >
              {overviewState.error}
            </Typography>
          </Box>
        </Box>
      );
    }

    console.log(overviewState.data);
    // setClickedRow(departmentState?.data.scores[0]);
    return (
      <>
        <Box sx={{ py: 1 }}>
          <Box>
            <Button
              color="primary"
              component={RouterLink}
              startIcon={<ArrowLeftIcon />}
              to="/dashboard/reports"
              variant="text"
            >
              部署一覧
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              mb: 2
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {overviewState.data.deptName}
            </Typography>
            {/* <Box sx={{ flexGrow: 1 }} />
            <ActionsMenu actions={actions} /> */}
          </Box>
          <Divider />
          <Box sx={{ mt: 4, ml: 2 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                container
                item
                lg={6}
                spacing={3}
                sx={{ height: 'fit-content' }}
                xs={12}
              >
                <Card
                  // variant="outlined"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    mr: 1
                  }}
                >
                  <DepartmentSummeryTable
                    scores={overviewState.data?.scores}
                    scoresCount={overviewState.data?.scoreCount}
                    error={overviewState.error}
                    isLoading={overviewState.isLoading}
                    setClickedRow={setClickedRow}
                  />
                </Card>
              </Grid>
              <Grid
                container
                item
                lg={6}
                spacing={3}
                sx={{ height: 'fit-content' }}
                xs={12}
              >
                <Card
                  // variant="outlined"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1
                  }}
                >
                  <DepartmentSummeryEvaluation
                    row={getRow(clickedRow)}
                  />
                </Card>
              </Grid>
            </Grid>
          </Box>
          {/* <Grid
            container
            spacing={2}
            sx={{
              mt: 2
            }}
            wrap="wrap"
          >
            {stats.map(({ content, icon }) => (
              <Grid
                item
                key={content}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  width: {
                    md: 'auto',
                    xs: '100%'
                  }
                }}
              >
                {icon}
                <Typography
                  color="textSecondary"
                  sx={{ ml: 0.5 }}
                  variant="body2"
                >
                  {content}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Tabs
            allowScrollButtonsMobile
            sx={{ mt: 4 }}
            value={tabs.findIndex((tab) => tab.href === location.pathname)}
            variant="scrollable"
          >
            {tabs.map((option) => (
              <Tab
                component={RouterLink}
                key={option.href}
                label={option.label}
                to={option.href}
              />
            ))}
          </Tabs>
          <Divider /> */}
        </Box>
        <Outlet />
        <ConfirmationDialog
          message="Are you sure you want to ban this account? This can't be undone."
          onCancel={handleCloseBanDialog}
          onConfirm={handleBanAccount}
          open={banDialogOpen}
          title="Ban Department"
          variant="error"
        />
      </>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {renderContent()}
      </Container>
    </Box>
  );
};
