import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Button,
  Tooltip
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ProgressComponent } from '../progress-bar';

export const DepartmentSummeryEvaluation = (props) => {
  const {
    row: rowProps
  } = props;
  const [row, setRow] = useState(rowProps);
  useEffect(() => {
    setRow(rowProps);
  }, [rowProps]);
  console.log(`row: ${JSON.stringify(row)}`);

  const getValue = (tempValue, property) => {
    if (tempValue) {
      if (property === 'ym') {
        const val = String(tempValue);
        const year = val.substr(0, 4);
        const month = val.substr(4, 1) === '0' ? val.substr(5, 1) : val.substr(4, 2);
        tempValue = year.concat('年 ', month, '月');
      }
      return tempValue;
    }
    console.log(row[property]);
    if (property === 'ym') {
      const val = String(row[property]);
      const year = val.substr(0, 4);
      const month = val.substr(4, 1) === '0' ? val.substr(5, 1) : val.substr(4, 2);
      const result = year.concat('年 ', month, '月');
      return result;
    }
    return row[property];
  };

  const getScore = (row) => {
    const score = ((row?.item1_score
    + row?.item2_score
    + row?.item3_score
    + row?.item4_score
    + row?.item5_score
    + row?.item6_score) / 6).toFixed(1);
    return score;
  };

  const navigate = useNavigate();
  const params = useParams();
  const toChild = () => {
    const path = `/dashboard/reports/${params.deptCode}/detail`;
    console.log(`row-parent: ${row}`);
    navigate(path, { state: { data: row } });
  };

  return (
    <>
      <Box
        sx={{
          p: 1,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          color="textPrimary"
          variant="h4"
        >
          {getValue(row?.ym, 'ym')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Tooltip
            title="Google Workspace の効果的な利用状況を、偏差値で示します。50 が平均的な利用となります。"
            arrow
          >
            <Typography
              color="textPrimary"
              variant="h4"
              sx={{
                px: 1
              }}
            >
              XSCORE:
            </Typography>
          </Tooltip>
          <Typography
            color="textPrimary"
            variant="h4"
          >
            {getScore(row)}
          </Typography>
        </Box>
        <Typography> </Typography>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          flexDirection: 'row',
          display: 'grid',
          // gridTemplateColumns: '300px 400px',
          gridTemplateColumns: '1fr 1fr',
          // gap: 2,
          // gridTemplateColumns: 'auto',
          // justifyItems: 'flex-start',
          // p: 3
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            flexGrow: 1,
            minWidth: 200,
            margin: 2
          }}
        >
          <Tooltip
            title="利用者がGoogle Workspace にアクセスしているか・利用しているかを把握・評価するための指標群です。通常は、Google Workspace 利用の初期に注目する指標です。"
            arrow
          >
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{ pt: 2 }}
            >
              浸透・活用
            </Typography>
          </Tooltip>
          <ProgressComponent
            value={getValue(row?.item1_score, 'item1_score')}
            itemName="指標1 - Google Workspace の AU"
            desc="14日内にGoogle Workspaceにログインした利用者(AU: アクティブユーザー)の率"
            actual={getValue(row?.item1_day, 'item1_day')}
            average={getValue(row?.item1_average, 'item1_average')}
            unitName=" %"
          />
          <ProgressComponent
            value={getValue(row?.item2_score, 'item2_score')}
            itemName="指標2 - Google ドライブ の AU"
            desc="24時間内にGoogle ドライブにアクセスした利用者(AU: アクティブユーザー)の率"
            actual={getValue(row?.item2_day, 'item2_day')}
            average={getValue(row?.item2_average, 'item2_average')}
            unitName=" %"
          />
          <ProgressComponent
            value={getValue(row?.item3_score, 'item3_score')}
            itemName="指標3 - Google Workspace の利用データ容量"
            desc="1人あたりのその時点までのGoogle Workspace の利用データ容量(Googleドライブ のみならず Gmail や カレンダー も含め全てのサービスのデータ容量"
            actual={getValue(row?.item3_day, 'item3_day').toLocaleString()}
            average={getValue(row?.item3_average, 'item3_average').toLocaleString()}
            unitName=" MB/人"
          />
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.default',
            flexGrow: 1,
            minWidth: 200,
            margin: 2
          }}
        >
          <Tooltip
            title="利用者が協働的で生産性の高い働き方に移行できているかを把握・評価するための指標群です。"
            arrow
          >
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{ pt: 2 }}
            >
              協働的働き方
            </Typography>
          </Tooltip>
          <ProgressComponent
            value={getValue(row?.item4_score, 'item4_score')}
            itemName="指標4 - Google ドライブの利用"
            desc="1人あたりの24時間内のGoogle ドライブに新規作成・格納数"
            actual={getValue(row?.item4_day, 'item4_day')}
            average={getValue(row?.item4_average, 'item4_average')}
            unitName=" 回/日/人"
          />
          <ProgressComponent
            value={getValue(row?.item5_score, 'item5_score')}
            itemName="指標5 - 共同編集ファイルの編集"
            desc="1人あたりの24時間内のGoogle ドキュメントエディタ(Googleドキュメント、スプレッドシート、スライド、フォーム)の利用数"
            actual={getValue(row?.item5_day, 'item5_day')}
            average={getValue(row?.item5_average, 'item5_average')}
            unitName=" 回/日/人"
          />
          <ProgressComponent
            value={getValue(row?.item6_score, 'item6_score')}
            itemName="指標6 - 他者作成のファイル編集"
            desc="1人あたりの24時間内の他者作成のGoogle ドキュメントエディタの協働アクション(「編集」、「コメント付与」)数"
            actual={getValue(row?.item6_day, 'item6_day')}
            average={getValue(row?.item6_average, 'item6_average')}
            unitName=" 回/日/人"
          />
        </Box>
      </Box>
      {/* <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          minWidth: 200,
          margin: 2
        }}
      >
        <Tooltip
          arrow
          title="スコアで表現された状況を、これまでにストリートスマートがお客様をご支援してきた経験・知見にもとづき、解説します。"
        >
          <Typography
            color="textPrimary"
            variant="h6"
            sx={{ pt: 2 }}
          >
            評価のコメント
          </Typography>
        </Tooltip>
        <Button onClick={toChild}>
          <Box
            sx={{
              alignItems: 'center',
              flexDirection: 'row',
              display: 'grid',
              gridTemplateColumns: '580px 20px',
              gap: 2,
              // gridTemplateColumns: '10fr 1fr',
              justifyItems: 'flex-start',
              // p: 3
            }}
          >
            <Box
              sx={{
                textAlign: 'left'
              }}
            >
              <p>
                {getValue(row?.comment, 'comment')}
              </p>
            </Box>
            <Box>
              <ArrowForwardIosIcon />
            </Box>
          </Box>
        </Button>
      </Box> */}
    </>
  );
};
