import {
  CardMedia,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export const RecommendedComponent = (props) => {
  const data = props;

  return (
    <Box sx={{ pt: 1 }}>
      <Container maxWidth="lg">
        <Card
          elevation={0}
          sx={{ backgroundColor: 'neutral.100' }}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{
              py: {
                md: 2,
                xs: 1
              },
              px: {
                md: 8,
                xs: 3
              }
            }}
          >
            {data?.title}
          </Typography>
          <Grid
            container
            sx={{
              pb: {
                md: 2,
                xs: 1
              },
              px: {
                md: 8,
                xs: 3
              }
            }}
          >
            <Grid
              item
              md={6}
              sx={{
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                flexDirection: 'column',
                mb: {
                  md: 0,
                  xs: 4
                },
                pr: {
                  md: 4
                }
              }}
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="subtitle1"
                sx={{
                  mb: 1,
                  mt: 1
                }}
              >
                {data?.desc}
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pl: {
                  md: 4
                }
              }}
              xs={12}
            >
              <a
                target="_blank"
                href={data?.linkUrl}
                rel="noreferrer"
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={data?.img}
                  alt="Paella dish"
                />
              </a>
              {/* <RouterLink
                to={{ pathname: data?.link_url }}
                target="_blank"
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={data?.img}
                  alt="Paella dish"
                />
              </RouterLink> */}
              {/* <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                  pt: {
                    md: 2
                  }
                }}
              >
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ flexGrow: 1 }} />
                <Button
                  color="primary"
                  component="a"
                  href={data?.linkUrl}
                  target="_blank"
                  variant="text"
                >
                  {data?.linkName}
                </Button>
              </Box> */}
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Box>
  );
};
