import axios from 'axios';
import { clientEenv } from '../config';
import user from './user';

console.log(`user : ${user}`);
const token = user !== null ? user.accessToken : '';
console.log(`token: ${token}`);
console.log(`clientEnv: ${clientEenv}`);
const url = clientEenv === 'development' ? 'http://localhost:8000' : 'https://mr2ent-server.an.r.appspot.com/';
const http = axios.create({
  baseURL: url,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  timeout: 30000,
});

export default http;
