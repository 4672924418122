import {
  Box,
  Typography,
  Tooltip
} from '@material-ui/core';
import ProgressBar from '@ramonak/react-progress-bar';

export const ProgressComponent = (props) => {
  const { value, itemName, desc, actual, average, unitName } = props;

  const getBgColor = (value) => {
    let color;
    if (value < 47) color = '#e69138';
    else if (value >= 47 && value < 53) color = '#6aa750';
    else color = '#3b7ad8';
    return color;
  };

  const getBaseColor = (value) => {
    let color;
    if (value < 47) color = '#fce5c5';
    else if (value >= 47 && value < 53) color = '#d9ead3';
    else color = '#c7daf7';
    return color;
  };

  const getValue = (value) => {
    if (value) return value;
    return props.value;
  };

  return (
    <Box
      sx={{
        py: 2
      }}
    >
      <Tooltip
        title={desc}
        arrow
      >
        <Typography
          variant="caption"
        >
          {itemName}
        </Typography>
      </Tooltip>
      <ProgressBar
        maxCompleted={80}
        completed={getValue(value)}
        bgColor={getBgColor(value)}
        baseBgColor={getBaseColor(value)}
        labelColor="#ffffff"
        transitionDuration="10"
        customLabel={String(value)}
      />
      <Typography
        variant="caption"
      >
        {'実績値: '}
        {actual}
        {unitName}
        {' | 平均値: '}
        {average}
        {unitName}
      </Typography>
    </Box>
  );
};
