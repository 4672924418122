import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PropTypes, { number } from 'prop-types';
import { Box, Divider, Drawer, IconButton, List } from '@material-ui/core';
import { DashboardSidebarItem } from './dashboard-sidebar-item';
import { Scrollbar } from './scrollbar';
import { ChevronLeft as ChevronLeftIcon } from '../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';
import { Cog as CogIcon } from '../icons/cog';
import { Eye as EyeIcon } from '../icons/eye';
import { CustomChartPie as ChartPieIcon } from '../icons/custom-chart-pie';
import { DocumentText as DocumentTextIcon } from '../icons/document-text';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import user from '../utils/user';

if (user) {
  console.log(`userInfo: ${JSON.stringify(user.userInfo)}`);
}
const userRole = (user) ? Number(user.userInfo.user_role) : 1;
const hasDict = (user) ? user.userInfo.has_dict : false;
const initialDataUu = (user) ? user.userInfo.initial_data_uu : false;
const initialDataCu = (user) ? user.userInfo.initial_data_cu : false;
const items = [
  {
    icon: DocumentTextIcon,
    title: 'レポート',
    hasDict: true,
    user_role: 1,
    items: [
      {
        href: '/dashboard/reports',
        title: '部署一覧',
        user_role: 1
      },
    ]
  },
  {
    icon: ChartPieIcon,
    title: '分析',
    hasDict: true,
    user_role: 1,
    items: [
      {
        href: '/dashboard/analysis',
        title: '6つの指標',
        user_role: 1,
        hasDict: true
      },
      {
        href: '/dashboard/analysis/transition',
        title: '推移',
        user_role: 1,
        hasDict: true
      },
      {
        href: '/dashboard/analysis/setting',
        title: '部署の設定',
        user_role: 1,
        hasDict: true
      }
    ]
  },
  // {
  //   icon: ChartBarIcon,
  //   title: '分析 - 組織全体',
  //   user_role: 1,
  //   hasDict: true,
  //   isDefault: false,
  //   items: [
  //     {
  //       href: '/dashboard/analysis',
  //       title: '6つの指標',
  //       user_role: 1
  //     },
  //     {
  //       href: '/dashboard/analysis/transition',
  //       title: '推移',
  //       user_role: 1
  //     }
  //   ]
  // },

  {
    icon: ChartBarIcon,
    title: '簡易レポート',
    isCu: true,
    items: [
      {
        href: '/dashboard/simplified',
        title: '簡易レポート',
        isCu: true
      },
    ]
  },
  {
    icon: CogIcon,
    title: '設定',
    user_role: 5,
    isDefault: true,
    items: [
      {
        href: '/dashboard/config',
        title: '利用者の管理',
        user_role: 5
      },
      {
        href: '/dashboard/config/notifications',
        title: '設定と初期データ準備状況',
        user_role: 5
      }
    ]
  },
  {
    icon: EyeIcon,
    title: 'サービス管理者',
    service_admin: 'mr@master-program.com',
    items: [
      {
        href: '/dashboard/service-admin',
        title: '顧客の管理',
        service_admin: 'mr@master-program.com'
      },
    ]
  },
];

export const DashboardSidebar = (props) => {
  const { onPin, pinned } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeHref, setActiveHref] = useState('');
  const [hovered, setHovered] = useState(false);

  const handleOpenItem = (item) => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    items.forEach((item) => {
      console.log(`item: ${JSON.stringify(item)}`);
      console.log(`path: ${pathname}`);
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname]);

  const handleMenu = (item) => {
    console.log(`handlemenu: ${JSON.stringify(item)}`);
    if (item.isDefault === true) return true;
    if (item.hasDict === true && hasDict === true && initialDataUu === 9) return true;
    if (item.isCu === true && initialDataCu === 9) return true;
    if (item.hasDict !== true && hasDict !== true && item.user_role <= userRole) return true;
    if (item.service_admin === user.userInfo.email) return true;
    // ユーザーがmr@master-program.com の場合の制御を追加
    //  Default表示の制御を追加
    return false;
  };

  return (
    <Drawer
      open
      sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        onMouseOver: () => { setHovered(true); },
        onMouseLeave: () => { setHovered(false); },
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px)',
          overflowX: 'hidden',
          top: 64,
          transition: 'width 250ms ease-in-out',
          width: pinned ? 270 : 73,
          '& .simplebar-content': {
            height: '100%'
          },
          '&:hover': {
            width: 270,
            '& span, p': {
              display: 'flex'
            }
          }
        }
      }}
    >
      <Scrollbar
        style={{
          display: 'flex',
          flex: 1,
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2
          }}
        >
          <List disablePadding>
            {activeItem && (items.map((item) => (
              // activeItem?.title === item.title
              // item?.user_role <= userRole || item?.hasDict === true
              handleMenu(item)
                ? (
                  <DashboardSidebarItem
                    active={activeItem?.title === item.title}
                    activeHref={activeHref}
                    key={item.title}
                    onOpen={() => handleOpenItem(item)}
                    open={openedItem?.title === item.title && (hovered || pinned)}
                    pinned={pinned}
                    {...item}
                  />
                )
                : false
            )))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box sx={{ pt: 1 }}>
            <IconButton onClick={onPin}>
              {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool
};
