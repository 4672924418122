import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { useAuth } from '../hooks/use-auth';
import { Login } from '../containers/login';

export const AuthGuard = (props) => {
  const userJson = sessionStorage.getItem('user');
  const auth = {};
  if (userJson) {
    // const user = JSON.parse(userJson);
    auth.isAuthenticated = true;
  } else {
    auth.isAuthenticated = false;
  }
  const { children } = props;
  console.log(`props ${JSON.stringify(children)}`);
  // const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return (
      <Login />
    );
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};
