import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import { CheckCircleOutlined as CheckCircleIcon } from '../../icons/check-circle-outlined';

const description = 'Google Workspace の利用ログを活用し、部署ごとに利用の浸透度合い、協働的な活用の状況を視覚化します。また、複数部署の状況の比較や、月次の推移を確認できます。';

const features = [
  'グーグルが提唱する考え方にもとづき、「浸透・活用」、「協働的な働き方」の2つの観点で、利用状況を視覚化します。',
  '専用ウェブ画面でインタラクティブ（対話・双方向的）に利用できます。',
  'Master Program レポート機能 をご利用のすべての利用企業も含めての相対評価で数値化します。',
  '部署ごと・月ごとの利用状況を確認できます。',
  '最大10部門を選択し、利用状況の比較を行えます。',
  '過去12ヶ月の範囲内で、自由に期間を設定して比較を行えます。'
];

export const ProductFeatures = () => (
  <div>
    <Typography
      color="textPrimary"
      sx={{
        mb: 3,
        mt: 1
      }}
      variant="body1"
    >
      {description}
    </Typography>
    <List sx={{ py: 2 }}>
      {features.map((feature) => (
        <ListItem
          disableGutters
          key={feature}
        >
          <ListItemIcon
            sx={{
              minWidth: 'auto',
              mr: 1
            }}
          >
            <CheckCircleIcon sx={{ color: 'success.main' }} />
          </ListItemIcon>
          <ListItemText primary={feature} />
        </ListItem>
      ))}
    </List>
    <Divider />
    <Typography
      color="textSecondary"
      sx={{
        mb: 3,
        mt: 1
      }}
      variant="body2"
    >
      © 2023 STREET SMART Co., Ltd.
    </Typography>
  </div>
);
