import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  IconButton,
  Link,
  Skeleton,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { Pagination } from '../pagination';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';
import { Scrollbar } from '../scrollbar';

const columns = [
  {
    id: 'ym',
    label: '年月'
  },
  {
    id: 'scoreMain',
    label: 'xScore'
  },
  {
    id: 'scorePenetration',
    label: '浸透・活用'
  },
  {
    id: 'scoreCollaboration',
    label: '協働的働き方'
  },
  {
    id: 'headcount',
    label: '人数'
  }
];

export const DepartmentSummeryTable = (props) => {
  const {
    scores: scoresProp,
    scoresCount,
    error,
    isLoading,
    setClickedRow,
    Tooltip
  } = props;
  const [scores, setScores] = useState(scoresProp);

  useEffect(() => {
    setScores(scoresProp);
  }, [scoresProp]);

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !scores?.length);

  // const [selectedRow, setSelectedRow] = useState({});
  const setSelectedRow = (row) => {
    setClickedRow(row);
    console.log(row);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 400 }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    padding={column.disablePadding ? 'none' : 'normal'}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {scores?.map((row) => (
                <TableRow
                  hover
                  key={row.ym}
                  onClick={() => setSelectedRow(row)}
                >
                  <TableCell>
                    {row.ym}
                  </TableCell>
                  <TableCell>
                    {((row.item1_score
                      + row.item2_score
                      + row.item3_score
                      + row.item4_score
                      + row.item5_score
                      + row.item6_score) / 6).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {((row.item1_score
                      + row.item2_score
                      + row.item3_score) / 3).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {((row.item4_score
                      + row.item5_score
                      + row.item6_score) / 3).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {(row.userCount / row.dateCount).toFixed(0)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
    </Box>
  );
};

DepartmentSummeryTable.defaultProps = {
  scores: [],
  scoresCount: 0
};

DepartmentSummeryTable.propTypes = {
  scores: Proptypes.array,
  scoresCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
};
