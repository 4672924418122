import { createSvgIcon } from '@material-ui/core/utils';

export const CustomShoppingCart = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 1C2.73478 1 2.48043 1.10536 2.29289 1.29289C2.10536 1.48043 2 1.73478 2 2C2 2.26522 2.10536 2.51957 2.29289 2.70711C2.48043 2.89464 2.73478 3 3 3H4.22L4.525 4.222C4.52803 4.23607 4.53136 4.25007 4.535 4.264L5.893 9.694L5 10.586C3.74 11.846 4.632 14 6.414 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13C16 12.7348 15.8946 12.4804 15.7071 12.2929C15.5196 12.1054 15.2652 12 15 12H6.414L7.414 11H14C14.1857 10.9999 14.3676 10.9481 14.5255 10.8504C14.6834 10.7528 14.811 10.6131 14.894 10.447L17.894 4.447C17.9702 4.29458 18.0061 4.12522 17.9985 3.95501C17.9908 3.78479 17.9398 3.61935 17.8502 3.47439C17.7606 3.32944 17.6355 3.20977 17.4867 3.12674C17.3379 3.04372 17.1704 3.00009 17 3H6.28L5.97 1.757C5.91583 1.54075 5.79095 1.34881 5.61521 1.21166C5.43946 1.0745 5.22293 1.00001 5 1H3Z" />
    <g opacity="0.5">
      <path d="M15.5607 17.5607C15.842 17.2794 16 16.8978 16 16.5C16 16.1022 15.842 15.7206 15.5607 15.4393C15.2794 15.158 14.8978 15 14.5 15C14.1022 15 13.7206 15.158 13.4393 15.4393C13.158 15.7206 13 16.1022 13 16.5C13 16.8978 13.158 17.2794 13.4393 17.5607C13.7206 17.842 14.1022 18 14.5 18C14.8978 18 15.2794 17.842 15.5607 17.5607Z" />
      <path d="M7.56066 17.5607C7.27936 17.842 6.89782 18 6.5 18C6.10218 18 5.72064 17.842 5.43934 17.5607C5.15804 17.2794 5 16.8978 5 16.5C5 16.1022 5.15804 15.7206 5.43934 15.4393C5.72064 15.158 6.10218 15 6.5 15C6.89782 15 7.27936 15.158 7.56066 15.4393C7.84196 15.7206 8 16.1022 8 16.5C8 16.8978 7.84196 17.2794 7.56066 17.5607Z" />
    </g>
  </svg>, 'CustomShoppingCart'
);
