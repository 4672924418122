import { RecommendedComponent } from './recommended-component';

export const RecommendedComponents = (props) => {
  const { data } = props;
  console.log(`data: ${data}`);

  return (
    <>
      {data?.map((item) => (
        <RecommendedComponent
          key={item.key}
          title={item.counterplan_title}
          desc={item.counterplan_description}
          img={item.thumbnail_url}
          linkName="こちら"
          linkUrl={item.link_url}
        />
      ))}
    </>
  );
};
